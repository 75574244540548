import React, {useEffect, useState} from 'react';

import { pdfjs, Document, Page } from 'react-pdf';

import CV_PDF from './XiaoyunZHONG_CV.pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import '../../css/Info.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const Info = () => {

    useEffect(() => {

        document.body.classList.remove('loading');
    
        // 创建一个 MutationObserver 实例来隐藏 alpha banner
        const alphaBannerObserver = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const alphaBanner = document.querySelector('.vAygCK-api-load-alpha-banner');
                    if (alphaBanner) {
                        alphaBanner.style.display = 'none';
                        // 找到元素后停止观察
                        observer.disconnect();
                    }
                }
            }
        });
    
        const alphaBannerConfig = { childList: true, subtree: true };
        alphaBannerObserver.observe(document.body, alphaBannerConfig);
    
        return () => {
            alphaBannerObserver.disconnect();
        };
    }, []);

    const [numPages, setNumPages] = useState(null);
    const [activeSection, setActiveSection] = useState('about');

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const video_1 = `${process.env.PUBLIC_URL}/videos/info_1.MOV`
    const video_2 = `${process.env.PUBLIC_URL}/videos/info_2.mp4`
    const video_3 = `${process.env.PUBLIC_URL}/videos/info_3.mov`
    const video_4 = `${process.env.PUBLIC_URL}/videos/info_4.MOV`
    const video_5 = `${process.env.PUBLIC_URL}/videos/info_5.mp4`
    const video_6 = `${process.env.PUBLIC_URL}/videos/info_6.mov`
    const video_7 = `${process.env.PUBLIC_URL}/videos/info_7.MOV`
    const video_8 = `${process.env.PUBLIC_URL}/videos/info_8.MOV`

    return (
        <div className="info-container">
            <div className="info-content">
                <div className="info-left">
                    <div className="profile-section">
                        <div className="profile-image-container">
                            <img src="selfie.JPG" alt="Profile" className="profile-image" />
                        </div>
                        <h1 className="name">Xiaoyun ZHONG</h1>
                        <p className="title">Engineer, Artistic practitioner</p>
                        <div className="social-links">
                            <a href="https://orcid.org/0009-0003-0105-1467" target="_blank" rel="noopener noreferrer" className="social-link">
                                <i className="fab fa-orcid"></i>
                            </a>
                            <a href="https://github.com/cloudosed" target="_blank" rel="noopener noreferrer" className="social-link">
                                <i className="fab fa-github"></i>
                            </a>
                            <a href="https://www.linkedin.com/in/cloudosed/" target="_blank" rel="noopener noreferrer" className="social-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                        </div>
                        <a href="mailto:cloudosed@gmail.com" className="email-link">
                            ✉️ cloudosed@gmail.com
                        </a>
                        <a href="mailto:xzhong204@connect.hkust-gz.edu.cn" className="email-link">
                            ✉️ xzhong204@connect.hkust-gz.edu.cn
                        </a>
                    </div>

                    <nav className="info-nav">
                        <button 
                            className={`nav_switch_button ${activeSection === 'about' ? 'active' : ''}`}
                            onClick={() => setActiveSection('about')}
                        >
                            About Me
                        </button>
                        <button 
                            className={`nav_switch_button ${activeSection === 'cv' ? 'active' : ''}`}
                            onClick={() => setActiveSection('cv')}
                        >
                            CV
                        </button>
                    </nav>
                </div>

                <div className="info-right">
                    {activeSection === 'about' ? (
                        <div className="about-section">
                            <h2>About Me</h2>
                            <div className="bio">
                                <p>Hello! I'm Xiaoyun ZHONG, I was born in Jiangxi Province in 2002.</p>

                                <p>I graduated from the School of Computer Science at Wuhan University 
                                and is currently studying as a MPhil student in Computational Media and 
                                Art at the Hong Kong University of Science and Technology (Guangzhou).
                                My research areas include a critical perspective on the practice of artificial 
                                intelligence technology in artistic creation and human-computer interaction, the 
                                design research of AI agent systems in video games, and VR cultural practice. 
                                My art papers will be presented at Siggraph Asia this December.</p>

                                <p>In my free time I play video games, my recent favorites include Disco Elysium, 
                                    P5R, Baldur's Gate 3, NieR: Automata, etc. 
                                    I also take my film camera around with me, and I also like reading and surfing.</p>
                            </div>
                            
                            <div className="video-gallery">
                                <div className="video-grid">
                                    <div className="video-item">
                                        <video 
                                            autoPlay 
                                            loop 
                                            muted 
                                            playsInline 
                                            className="video-content"
                                            src={video_1}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="video-item">
                                        <video 
                                            autoPlay 
                                            loop 
                                            muted 
                                            playsInline 
                                            className="video-content"
                                            src={video_2}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="video-item">
                                        <video 
                                            autoPlay 
                                            loop 
                                            muted 
                                            playsInline 
                                            className="video-content"
                                            src={video_3}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="video-item">
                                        <video 
                                            autoPlay 
                                            loop 
                                            muted 
                                            playsInline 
                                            className="video-content"
                                            src={video_4}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="video-item">
                                        <video 
                                            autoPlay 
                                            loop 
                                            muted 
                                            playsInline 
                                            className="video-content"
                                            src={video_5}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="video-item">
                                        <video 
                                            autoPlay 
                                            loop 
                                            muted 
                                            playsInline 
                                            className="video-content"
                                            src={video_6}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="video-item">
                                        <video 
                                            autoPlay 
                                            loop 
                                            muted 
                                            playsInline 
                                            className="video-content"
                                            src={video_7}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="video-item">
                                        <video 
                                            autoPlay 
                                            loop 
                                            muted 
                                            playsInline 
                                            className="video-content"
                                            src={video_8}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="cv-section">
                            <Document
                                file={CV_PDF}
                                onLoadSuccess={onDocumentLoadSuccess}
                                className="cv-document"
                            >
                                {Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                        <Page
                                            renderTextLayer={false}
                                            customTextRenderer={false}
                                            scale={1.2}
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            className="cv-page"
                                        />
                                    ),
                                )}
                            </Document>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Info;